@font-face {
  font-family: "Cooper Hewitt";
  font-weight: normal;
  src: url("./font/CooperHewitt-Book.woff");
}
@font-face {
  font-family: "Cooper Hewitt Medium";
  src: url("./font/CooperHewitt-Medium.woff");
}
@font-face {
  font-family: "Cooper Hewitt Heavy";
  src: url("./font/CooperHewitt-Heavy.woff");
  font-weight: bolder;
}

/* layout */

html {
  font-family: "Cooper Hewitt", sans-serif;
  font-size: 1.2rem;
  line-height: 1.4em;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
}

main nav {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.Content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  clear: both;
}

/* styles */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5em;
}

h1,
h3,
h5 {
  font-family: "Cooper Hewitt Medium";
}

h1,
h2 {
  font-size: 2rem;
}
h3,
h4 {
  font-size: 1.5rem;
}
h5,
h6 {
  font-size: 1.2rem;
}

p {
  margin-bottom: 1rem;
}

strong {
  font-family: "Cooper Hewitt Medium";
}

footer {
  font-size: 0.85rem;
}

/* custom: header */

body.bot > header {
  display: none;
}

body > header {
  background-color: #c00;
  color: #fff;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

body > header h1 {
  font-family: "Cooper Hewitt Heavy";
  font-size: 2rem;
  line-height: 1em;
  margin: 0;
}

body > header h2 {
  font-style: italic;
  font-size: 1rem;
  margin: 0;
}

body > header > nav {
  font-family: "Cooper Hewitt Heavy";
  text-align: right;
}

body > header > nav a {
  color: white;
  display: block;
}

main {
  max-width: 28rem;
  padding: 0 1rem;
  margin: auto;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  main {
    max-width: 49rem !important;
    padding: 0 2rem;
  }
}
